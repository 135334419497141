<template>
  <div>
    <home-navigation></home-navigation>

    <el-divider></el-divider>

    <home-charts
      v-if="$canAndRulesPass(user, 'main_page.see_graph_plan') || $canAndRulesPass(user, 'main_page.see_graph_assessment')"
    ></home-charts>

  </div>
</template>

<script>

import {mapGetters} from "vuex";
import HomeNavigation from "@/componentsCompany/goldenapple/home/HomeNavigation.vue";
import HomeCharts from "@/componentsCompany/goldenapple/home/HomeCharts.vue";

export default {
  name: 'Home',
  components: {HomeCharts, HomeNavigation},

  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters(['user']),
  },
  mounted() {
  },
  methods: {}
}

</script>

<style lang="scss">

</style>